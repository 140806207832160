import Router from "next/router";

export class CtxRedirect {
    static redirect(ctx, path) {
        if (ctx.res) {
            ctx.res.writeHead(302, { Location: path });
            ctx.res.end();
        } else {
            Router.push(path);
        }
    }
}
