import { observable, action, runInAction, makeObservable } from "mobx";
import RootStore from "../RootStore";
import NotificationStore from "../NotificationStore";
import localLocations from "../../../www/locations.json";


class LocationStore {
    locations = [];

    locationsInfo = [];

    startLocationsInfo = [];

    finishLocationsInfo = [];

    AIRPORT_ADDRESS = "VÁCLAV HAVEL AIRPORT PRAGUE (PRG) - Aviatická, 161 00 Praha 6, Česko";

    PRAGUE_CENTER_ADDRES = "Prague City Centre - Praha, Česko";

    timeout = null;

    onLoad = null;

    TYPE_HOTEL = 1;

    TYPE_AIRPORT = 2;

    TYPE_TRAIN = 3;

    TYPE_CITY = 4;

    TYPE_BUS = 5;

    TYPES = [
        { id: 1, name: "hotel", iconName: "hotel" },
        { id: 2, name: "letiště", iconName: "airport" },
        { id: 3, name: "vlak", iconName: "train" },
        { id: 4, name: "centrum", iconName: "city" },
        { id: 5, name: "autobus", iconName: "bus" },
    ];


    constructor() {
        makeObservable(this, {
            locations: observable,
            locationsInfo: observable,
            startLocationsInfo: observable,
            finishLocationsInfo: observable,
            preload: action,
            searchLocation: action,
            loadLocations: action,
            setCallback: action,
            clearCallback: action,
        });
    }


    setCallback(cb) {
        this.onLoad = cb;
    }

    clearCallback() {
        this.onLoad = null;
    }

    preload() {
        RootStore.api.get("/locations/").then((response) => {
            runInAction(() => {
                this.locations = response.items ? response.items : response;
            });
        }).catch((e) => {
            NotificationStore.processException(e, "search-location");
        });
    }

    searchLocation(query) {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
            RootStore.api.get("/locations/", { query }).then((response) => {
                runInAction(() => {
                    this.locations = response.items ? response.items : response;
                });
            }).catch((e) => {
                NotificationStore.processException(e, "search-location");
            });
        }, 500);
    }

    /**
     * Load locations from the local JSON file.
     */
    loadLocations = async () => {
        try {
            runInAction(() => {
                this.locations = localLocations;
                if (this.onLoad) {
                    this.onLoad();
                }
            });
        } catch (error) {
            runInAction(() => {
                NotificationStore.processException(error, "load-locations");
            });
        }
    };
}

export default new LocationStore();
