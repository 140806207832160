import { observable, makeObservable } from "mobx";
import moment from "moment-timezone";
import RootStore from "../RootStore";
import NotificationStore from "../NotificationStore";
import CarCategoryDecorator from "./CarCategoryDecorator";
import CarCategoryCombinationDecorator from "./CarCategoryCombinationDecorator";
import NewOrderStore from "../Order/NewOrderStore";
import ZoneStore from "../Zone/ZoneStore";

class CarCategoryStore {
    isLoading = false;

    carCategories = [];

    customCarCategories = [];

    zoneInfo = "other";

    returnZoneInfo = "other";

    routeDistance = 0;

    routeDuration = 0;

    routeTotalDuration = 0;

    isStartPrague = false;

    isFinishPrague = false;

    isReturnStartPrague = false;

    isReturnFinishPrague = false;

    chargeLastExcursionPoint = true;

    isCarDeliveryPrice = false;

    constructor() {
        makeObservable(this, {
            isLoading: observable,
            carCategories: observable,
            customCarCategories: observable,
            zoneInfo: observable,
            returnZoneInfo: observable,
            routeDistance: observable,
            routeDuration: observable,
            routeTotalDuration: observable,
            chargeLastExcursionPoint: observable,
            isCarDeliveryPrice: observable
        });
    }

    getNextWorkingDay() {
        const day = moment().add(5, "day").set({ hour: 12, minute: 0, second: 0 });

        if (day.day() === 6) {
            day.add(2, "day");
        }

        if (day.day() === 7) {
            day.add(1, "day");
        }

        return day;
    }



    load(transferType, query, selectedCarsReset = true) {
        
        let queryWithPickupDate = {
            ...query,
            ...NewOrderStore.formatPickupDateTimes(),
            pickupDate: this.getNextWorkingDay(),
        };
        
        if (transferType === "transfer") {
            queryWithPickupDate = {
                ...queryWithPickupDate,
                ...NewOrderStore.addDiscountTimes(),
            };
        }

        
      
        const promise = RootStore.api.get(`/car-categories-combinations/${transferType}/`, queryWithPickupDate).then((response) => {
            this.isCarDeliveryPrice = response.isCarDeliveryPrice;
            this.isStartPrague = response.isStartPrague;
            this.isFinishPrague = response.isFinishPrague;
            this.isReturnStartPrague = response.isReturnStartPrague;
            this.isReturnFinishPrague = response.isReturnFinishPrague;

            if (response.priceData) {
                this.chargeLastExcursionPoint = response.priceData.chargeLastExcursionPoint;
            }

            if (NewOrderStore.returnRouteMode) {
                this.zoneInfo = response.returnZoneInfo;
                this.returnZoneInfo = response.zoneInfo;
            } else {
                this.zoneInfo = response.zoneInfo;
                this.returnZoneInfo = response.returnZoneInfo;

                // Pouze pokud je objednavka nova (neni edit)
                if (!NewOrderStore.editId) {
                    if ([ZoneStore.TYPE_AIRPORT_FROM, ZoneStore.TYPE_AIRPORT_TO].includes(this.zoneInfo)) {
                        NewOrderStore.usePickupDateLive = true;
                    } else {
                        NewOrderStore.usePickupDateLive = false;
                    }
                }
            }

            this.carCategories = [];
            //let selectedCar = null;
            //let selectedCarIndex = null;
            //let finl=[];
            const arr=response.carCategories;
            //let mx= NewOrderStore.passengersCount+2;
            arr.forEach((ct, inp) => {

                //if(ct.isShowFirst==true && ct.capacity>=NewOrderStore.passengersCount && ct.capacity<=mx){
                if (ct.isShowFirst==true && NewOrderStore.preventRouteByUrlParameter) {
                    arr.splice(0, 0, arr.splice(inp, 1)[0]);
                }
            });

            //console.log("response.carCategories",response.carCategories);
            response.carCategories.forEach((category, index) => {
                // issue: 825
                // In case the route start and finish is not in Prague, backend is sending null prices.
                // if (category.price <= 0) { return; }

                if (category.combination) {
                    this.carCategories.push(new CarCategoryCombinationDecorator(category));
                } else {
                    this.carCategories.push(new CarCategoryDecorator(category));
                }
                if (!NewOrderStore.preventRouteByUrlParameter)
                if (category.carCategoryId.toString() === NewOrderStore.carCategoryIdFromUrl && !NewOrderStore.preventRouteByUrlParameter) {
                    selectedCar = this.carCategories[index];
                    selectedCarIndex = index;
                }
    
            });

            this.customCarCategories = [];
            response.customCarCategories.forEach((customCategory) => {
                this.customCarCategories.push(new CarCategoryDecorator(customCategory));
            });
            this.routeDistance = response.distance;
            this.routeDuration = response.duration / 60;
            this.routeTotalDuration = (response.waitingTime || response.duration) ? (response.waitingTime + response.duration) / 60 : null;

            //console.log('selectedCarsReset', selectedCarsReset);

            if (selectedCarsReset) {
                NewOrderStore.selectedCars = {
                    thereRoute: [],
                    backRoute: [],
                };
            }

            NewOrderStore.setIfRouteHasToBePaidInAdvance();
            // const paymentType = PaymentStore.getSelectedCurrencies();
            // if (paymentType[0]) {
            //     NewOrderStore.paymentType = paymentType[0].id;
            // }


            if (NewOrderStore.carCategoryIdFromUrl) {
                if (NewOrderStore.rentHours <= 12) {
                    NewOrderStore.selectCar(selectedCar);
                }
            }

            this.isLoading = false;
            
        }).catch((e) => {
            this.isLoading = false;
            NotificationStore.processException(e, "car-category");
        });

        return Promise.all([promise]);
    }

    loadTransfer(start, finish, passengersCount, selectedCarsReset = true) {

        const isDateTimeSelected=NewOrderStore.formatPickupDateTimes();

        if(!isDateTimeSelected.pickupDateTime && !NewOrderStore.backRouteStep){
            return false;
        }

        this.isLoading = true;
        NotificationStore.reset();

        const query = {
            start,
            finish,
            passengersCount,
        };

        return this.load("transfer", query, selectedCarsReset);
    }

    loadHourRental(
        {
            start, finish, passengersCount, rentHours,
        },
        selectedCarsReset = true
    ) {

        const isDateTimeSelected=NewOrderStore.formatPickupDateTimes();

        if(!isDateTimeSelected.pickupDateTime){
            return false;
        }

        this.isLoading = true;
        NotificationStore.reset();

        const urlString = window.location.href;
        const url = new URL(urlString);

        const rentHoursNew = url.searchParams.get("rentHours");
        if (rentHoursNew && NewOrderStore.rentHours == 1) {
            rentHours = parseInt(rentHoursNew);
        }


        if (NewOrderStore.rentHours > 16) {
            rentHours = 16;
        }

        const query = {
            start,
            finish,
            passengersCount,
            rentHours,
        };


        return this.load("hour-rental", query, selectedCarsReset);
    }

    loadExcursion(excursionPoints, passengersCount, selectedCarsReset = true) {
        if (excursionPoints.length < 2 || (excursionPoints.length > 1 && !excursionPoints[1].place)) {
            return null;
        }

        const isDateTimeSelected=NewOrderStore.formatPickupDateTimes();

        if(!isDateTimeSelected.pickupDateTime){
            return false;
        }

        this.isLoading = true;

        const query = {
            excursionPoints: JSON.stringify(excursionPoints),
            passengersCount,
            start: excursionPoints[0].place,
            finish: excursionPoints[1].place,
        };

        return this.load("excursion", query, selectedCarsReset);
    }
}

export default new CarCategoryStore();
