import React, { Component } from "react";
import { observer } from "mobx-react";
import { withRouter } from "next/router";
import { Link } from "../Router.tsx";

class Logo extends Component {
    render() {
        const { router } = this.props;

        return (
            <div className="header__logo">
                <Link
                    route="index"
                    params={{}}
                >
                    <img
                        src="/img/logo.svg"
                        alt="Prague Airport Transfers logo"
                    />
                </Link>
            </div>
        );
    }
}

export default withRouter(observer(Logo)); 
