const routes = require("next-routes");
const translations = require("./www/translations");

const langs = Object.keys(translations);

module.exports = new routes();
module.exports.add({ name: "index", page: "/[lang]", pattern: "/:lang" });

const translationKeys = [
    // "page",
    "page-hour-rental", // => page-hour-rental, page-hour-rental-en, ..
    "page-excursion",
    "page-login",
    "page-new-password",
    "page-new-password/[token]",
    "page-orders",
    "page-settings",
    "page-partner",
    "page-Zajezdy",
    "page-Zajezdy/[slug]",
    "page-tourbookings",
    "page-stripePayment/[orderId]/[orderType]",
];

translationKeys.forEach((translationKey) => {
    const name = translationKey.replace("page-", ""); // login
    const page = `/[lang]/${name}`; // :lang/login

    const pattern = [];
    langs.forEach((lang) => {
        const translate = translations[lang].translation[translationKey];

        if (translate) {
            pattern.push(encodeURI(translate));
        }
    });


    const route = { name, page, pattern: `/:lang/:path(${pattern.join("|")})` }; // .replace(/\|/g, '|\')


    module.exports.add(route);
});
