import { observable, action, makeObservable } from "mobx";
import csCZ from "antd/lib/locale/cs_CZ";
import enUS from "antd/lib/locale/en_US";
import deDE from "antd/lib/locale/de_DE";
import arEG from "antd/lib/locale/ar_EG";
import bgBG from "antd/lib/locale/bg_BG";
import daDK from "antd/lib/locale/da_DK";
import elGR from "antd/lib/locale/el_GR";
import esES from "antd/lib/locale/es_ES";
import fiFI from "antd/lib/locale/fi_FI";
import frFR from "antd/lib/locale/fr_FR";
import heIL from "antd/lib/locale/he_IL";
import hrHR from "antd/lib/locale/hr_HR";
import huHU from "antd/lib/locale/hu_HU";
import itIT from "antd/lib/locale/it_IT";
import jaJP from "antd/lib/locale/ja_JP";
import koKR from "antd/lib/locale/ko_KR";
import lvLV from "antd/lib/locale/lv_LV";
import nbNO from "antd/lib/locale/nb_NO";
import plPL from "antd/lib/locale/pl_PL";
import ptPT from "antd/lib/locale/pt_PT";
import roRO from "antd/lib/locale/ro_RO";
import ruRU from "antd/lib/locale/ru_RU";
import svSE from "antd/lib/locale/sv_SE";
import thTH from "antd/lib/locale/th_TH";
import trTR from "antd/lib/locale/tr_TR";
import zhCN from "antd/lib/locale/zh_CN";
import 'dayjs/locale/cs';
import 'dayjs/locale/de';
import 'dayjs/locale/ar';
import 'dayjs/locale/bg';
import 'dayjs/locale/da';
import 'dayjs/locale/el';
import 'dayjs/locale/es';
import 'dayjs/locale/fi';
import 'dayjs/locale/fr';
import 'dayjs/locale/he';
import 'dayjs/locale/hr';
import 'dayjs/locale/hu';
import 'dayjs/locale/it';
import 'dayjs/locale/ja';
import 'dayjs/locale/ko';
import 'dayjs/locale/lv';
import 'dayjs/locale/nb';
import 'dayjs/locale/pl';
import 'dayjs/locale/pt';
import 'dayjs/locale/ro';
import 'dayjs/locale/ru';
import 'dayjs/locale/sv';
import 'dayjs/locale/th';
import 'dayjs/locale/tr';
import 'dayjs/locale/zh-cn';
import RootStore from "../RootStore";
import i18n from "../../core/i18n";
import CurrencyStore from "../Currency/CurrencyStore";

export const DEFAULT_LANG = "en";

class LanguageStore {
    lang = DEFAULT_LANG;

    languages = [];
    translations = [];

    pageTranslations = [];

    isLoading = false;

    // eslint-disable-next-line require-await
    getInit = async language => RootStore.api.get("/init", {
        language,
    });

    setInit = (init) => {
        const {
            language, languages, translations, pageTranslations,
        } = init;

        this.languages = languages;
        this.translations = translations;
        i18n.addResourceBundle(language, "translation", translations);
        this.pageTranslations = pageTranslations;

        // SET DEFAULT CURRENCY
        const langObj = this.languages.find(lang => lang.language === language);
        const currency = langObj?.currency || "CZK";
        CurrencyStore.selectCurrency(currency);
        CurrencyStore.selectTourCurrency(currency);

        if (typeof window !== "undefined") {
            const currencyNew = localStorage.getItem("CurrencyStoreCode");
            if (!currencyNew) {
                localStorage.setItem("CurrencyStoreCode", currency);
            }
        }

    
    };



    getLocaleAndDirection(lang) {
        const locales = {
            cs: csCZ,
            de: deDE,
            ar: arEG,
            bg: bgBG,
            da: daDK,
            el: elGR,
            es: esES,
            fi: fiFI,
            fr: frFR,
            he: heIL,
            hr: hrHR,
            hu: huHU,
            it: itIT,
            ja: jaJP,
            ko: koKR,
            lt: lvLV,
            no: nbNO,
            pl: plPL,
            pt: ptPT,
            ro: roRO,
            ru: ruRU,
            sv: svSE,
            th: thTH,
            tr: trTR,
            zh: zhCN,
        };

        const rtlLanguages = ["ar", "he"]; // Define RTL languages

        const locale = locales[lang] || enUS; // Default to enUS if not found
        const direction = rtlLanguages.includes(lang) ? "rtl" : "ltr";

        return { locale, direction };
    }

    // eslint-disable-next-line class-methods-use-this
    getPageTranslate(lang, key) {
        if (this.pageTranslations[lang] && this.pageTranslations[lang][key]) {
            return this.pageTranslations[lang][key];
        }
        return null;
    }


    getLocaleName(lang) {
        const found = this.languages.find((l) => {
            const languageKey = l.language === "cz" ? "cs" : l.language;
            return languageKey === lang;
        });
        if (found) return found.nativeName;
        return "";
    }

        /**
     * Set the current language
     * @param {String} lang
     */
        async setLanguage(lang) {
            if (!this.isLangExists(lang)) {
                console.warn(`Language ${lang} does not exist. Falling back to ${DEFAULT_LANG}.`);
                lang = DEFAULT_LANG;
            }
            await this.setInit(await this.getInit(lang));
        }

    isLangExists(languageKey) {
        const key = languageKey === "cs" ? "cz" : languageKey;

        let found = false;
        this.languages.forEach((language) => {
            if (language.language === key) {
                found = true;
            }
        });

        return found;
    }

    /**
     * Najde stejný nebo alternativní klíč dle klíče jazyka
     * @param {String} languageKey
     */
    findLanguageKey(languageKey) {
        const key = languageKey === "cs" ? "cz" : languageKey;

        let match = null;
        this.languages.forEach((language) => {
            if (language.language === key) {
                match = language.alternativeLanguage ? language.alternativeLanguage : language.language;
            }
        });
        return match;
    }

    /**
     * Najde telefonní předvolbu dle klíče jazyka
     * @param {String} languageKey
     */
    getPhonePrefix(languageKey) {
        const key = languageKey === "cs" ? "cz" : languageKey;

        let match = null;
        this.languages.forEach((language) => {
            if (language.language === key) {
                if (language.phonePreset) {
                    match = language.phonePreset;
                } else {
                    match = "+420";
                }
            }
        });
        return match;
    }

    getLocale = (router) => {
        const locale = router.query.lang === "cs" ? "cz" : router.query.lang;

        if (!this.languages.find(l => l.language === locale)) {
            return this.lang;
        }

        return locale;
    }
}

export default new LanguageStore();
