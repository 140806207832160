import { observable, makeObservable } from "mobx";
import OrderRouteCarDecorator from "./OrderRouteCarDecorator";
import ExtraDecorator from "../Extra/ExtraDecorator";
import CurrencyStore from "../Currency/CurrencyStore";
import i18n from "../../core/i18n";


export default class OrderRouteDecorator {
    id = null;

    passengersCount = null;

    rentHours = null;

    start = null;

    finish = null;

    startName = null;

    finishName = null;

    excursionPoints = [];

    isExcursion = false;

    isEditable = false;

    createDate = null;

    pickupDate = null;

    // @observable carCategory = null;
    totalPrice = null;

    totalPriceInPaymentCurrency = null;

    totalPriceInPaymentCurrencyMessage = "";

    totalPriceDriver = null;

    paymentCurrency = CurrencyStore.CZK;

    paymentType = null;

    flightNumber = null;

    trainNumber = null;

    busNumber = null;

    kids1 = null;

    kids2 = null;

    kids3 = null;

    note = null;

    noteForDriver = null;

    withLuggage = false;

    excessiveLuggage = false;

    contactPersonDiffers = false;

    contactPersonPhone = null;

    driver = null;

    distance = 0;

    distanceMessage = "0 km";

    duration = 0;

    durationMessage = "0 m";

    status = 0;

    stornoStatus = 0;

    statusName = null;

    stornoReason = null;

    driverStatus = 0;

    driverStatusName = null;

    paymentStatus = 0;

    arrear = 0;

    paymentStatusName = null;

    orderRouteCars = [];

    order = null;

    payments = [];

    extras = [];

    tasks = [];

    usePickupDateLive = false;


    constructor(data) {
        makeObservable(this, {
            passengersCount: observable,
            rentHours: observable,
            start: observable,
            finish: observable,
            startName: observable,
            finishName: observable,
            excursionPoints: observable,
            isExcursion: observable,
            isEditable: observable,
            createDate: observable,
            pickupDate: observable,
            totalPrice: observable,
            totalPriceInPaymentCurrency: observable,
            totalPriceInPaymentCurrencyMessage: observable,
            totalPriceDriver: observable,
            paymentCurrency: observable,
            paymentType: observable,
            flightNumber: observable,
            trainNumber: observable,
            busNumber: observable,
            kids1: observable,
            kids2: observable,
            kids3: observable,
            note: observable,
            noteForDriver: observable,
            withLuggage: observable,
            excessiveLuggage: observable,
            contactPersonDiffers: observable,
            contactPersonPhone: observable,
            driver: observable,
            distance: observable,
            distanceMessage: observable,
            duration: observable,
            durationMessage: observable,
            status: observable,
            stornoStatus: observable,
            statusName: observable,
            stornoReason: observable,
            driverStatus: observable,
            driverStatusName: observable,
            paymentStatus: observable,
            arrear: observable,
            paymentStatusName: observable,
            orderRouteCars: observable,
            order: observable,
            payments: observable,
            extras: observable,
            tasks: observable,
            usePickupDateLive: observable
        });

        if (data) {
            this.setAll(data);
        }
    }

    setAll(data) {
        this.id = data.id || null;
        this.passengersCount = data.passengersCount || null;
        this.rentHours = data.rentHours || null;
        this.start = data.start || null;
        this.finish = data.finish || null;
        this.startName = data.startName || null;
        this.finishName = data.finishName || null;
        this.excursionPoints = data.excursionPoints || null;
        this.isExcursion = data.isExcursion;
        this.isEditable = data.isEditable;
        this.createDate = data.createDate || null;
        this.pickupDate = data.pickupDate || null;
        this.usePickupDateLive = data.usePickupDateLive || false;
        this.carCategory = data.carCategory || null;
        this.totalPrice = data.totalPrice || null;
        this.totalPriceInPaymentCurrency = data.totalPriceInPaymentCurrency || null;
        this.totalPriceInPaymentCurrencyMessage = data.totalPriceInPaymentCurrencyMessage || "";
        this.totalPriceDriver = data.totalPriceDriver || null;
        this.paymentCurrency = data.paymentCurrency || CurrencyStore.CZK;
        this.paymentType = data.paymentType || null;
        this.flightNumber = data.flightNumber || null;
        this.trainNumber = data.trainNumber || null;
        this.busNumber = data.busNumber || null;
        this.kids1 = data.kids1 || null;
        this.kids2 = data.kids2 || null;
        this.kids3 = data.kids3 || null;
        this.note = data.note || null;
        this.noteForDriver = data.noteForDriver || null;
        this.withLuggage = data.withLuggage || false;
        this.excessiveLuggage = data.excessiveLuggage || false;
        this.contactPersonDiffers = data.contactPersonDiffers || false;
        this.contactPersonPhone = data.contactPersonPhone || null;
        this.driver = data.driver || null;

        this.distance = data.distance || 0;
        this.distanceMessage = data.distanceMessage || "0 km";
        this.duration = data.duration || 0;
        this.durationMessage = data.durationMessage || "0 m";

        this.status = data.status || 0;
        this.stornoStatus = data.stornoStatus || 0;
        this.statusName = data.statusName || i18n.t("Nepotvrzeno");
        this.stornoReason = data.stornoReason || null;
        this.driverStatus = data.driverStatus || 0;
        this.driverStatusName = data.driverStatusName || i18n.t("Bez reakce");
        this.arrear = data.arrear || 0;
        this.paymentStatus = data.paymentStatus || 0;
        this.paymentStatusName = data.paymentStatusName || i18n.t("Nezaplaceno");
        data.orderRouteCars.map(routeCar => this.orderRouteCars.push(new OrderRouteCarDecorator(routeCar)));

        this.extraOrderRoutes = data.extraOrderRoutes.map((extraOrderRoute) => {
            let extraOrderRouteTemp = extraOrderRoute;
            extraOrderRouteTemp = new ExtraDecorator(extraOrderRoute.extra);
            return extraOrderRouteTemp;
        }) || [];
        this.extras = this.extraOrderRoutes.map((extraOrderRoute) => {
            const { extra } = extraOrderRoute;
            if (extra) extra.amount = extraOrderRoute.amount; // TODO: why?
            return extra;
        });

        this.payments = data.payments || null;
        this.tasks = data.tasks || null;
        this.customer = data.customer || null;
        if (typeof data.order === "undefined") this.order = null;
        else if (typeof data.order === "number") this.order = data.order;
        else this.order = data.order.id;


        // data.tasks.map((task) => this.tasks.push(new TaskDecorator(task)));
    }

    swapStartAndFinish() {
        const { start } = this;
        const { finish } = this;
        this.start = finish;
        this.finish = start;
    }

    toggleContactPerson(flag) {
        this.contactPersonDiffers = flag;
        if (flag === false) {
            this.contactPersonPhone = null;
        }
    }
}
