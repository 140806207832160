import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";

i18n
    .use(reactI18nextModule) // passes i18n down to react-i18next
    .init({
        fallbackLng: "en",
        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
        debug: false,
        react: {
            wait: true,
        },
    });

export default i18n;
