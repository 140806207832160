import { observable, action, makeObservable } from "mobx";
import Api from "../core/Api";

class RootStore {
    location = null;

    history = null;

    /** @type {Api} */
    api = null;

    title = "";

    constructor() {
        makeObservable(this, {
            location: observable,
            title: observable,
            _updateLocation: action
        });

        this.api = new Api();
    }

    _updateLocation(newState) {
        this.location = newState;
    }
}

export default new RootStore();
