import { observable, makeObservable } from "mobx";

export default class TourDecorator {
    id = null;

    adultPrice = 0;

    childrenPrice = 0;

    documents = [];

    durationTime;

    startDate;
    endDate;

    excludesDescription;

    cancellation;

    infantPrice = 0;
    seniorPrice = 0;
    includesDescription;
    meetingPoint;
    name;
    slug;
    personGroup;
    personGroup2;
    personGroup3;
    personGroup4;
    photos;
    pricingType;
    shortDescription;
    thumbnail;
    tourGuideLanguage;
    tourType;
    longDescription;
    freePickup;
    operationDates = [];
    timeslots = [];

    
    
    constructor(data) {
        makeObservable(this, {
            adultPrice: observable,
            childrenPrice: observable,
            documents: observable,
            durationTime: observable,
            startDate: observable,
            endDate: observable,
            excludesDescription: observable,
            cancellation: observable,
            infantPrice: observable,
            seniorPrice: observable,
            includesDescription: observable,
            meetingPoint: observable,
            name: observable,
            slug: observable,
            personGroup: observable,
            personGroup2: observable,
            personGroup3: observable,
            personGroup4: observable,
            photos: observable,
            pricingType: observable,
            shortDescription: observable,
            thumbnail: observable,
            tourGuideLanguage: observable,
            tourType: observable,
            longDescription: observable,
            freePickup: observable,
            operationDates: observable,
            timeslots: observable
        });

        if (data) {
            this.setAll(data);
        }
    }
    

    setAll(data) {
       
        this.id = data.id || null;
        this.adultPrice = data.adultPrice || null;
        this.childrenPrice = data.childrenPrice || null;
        this.documents = data.documents || [];
        this.startDate = data.startDate || null;
        this.endDate = data.endDate || null;
        this.excludesDescription = data.excludesDescription || null;
        this.cancellation = data.cancellation || null;
        this.infantPrice = data.infantPrice || 0;
        this.seniorPrice = data.seniorPrice || 0;
        this.includesDescription = data.includesDescription || null;
        this.meetingPoint = data.meetingPoint || null;
        this.name = data.name || null;
        this.slug = data.slug || null;
        this.longDescription = data.longDescription || null;
        this.durationTime = data.durationTime || null;
        this.freePickup =  data.freePickup || null;
        
        this.operationDates = data.operationDates || [];
        this.timeSlots = data.timeSlots || [];
        
        this.personGroup = data.personGroup || 0;
        this.personGroup2 = data.personGroup2 || 0;
        this.personGroup3 = data.personGroup3 || 0;
        this.personGroup4 = data.personGroup4 || 0;
        this.photos = data.photos || 0;
        this.pricingType = data.pricingType || null;
        this.shortDescription = data.shortDescription || 0;
        this.thumbnail = data.thumbnail || 0;
        this.tourGuideLanguage = data.tourGuideLanguage || 0;
        this.tourType = data.tourType || null;
        
    }
}
