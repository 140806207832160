import { observable, action, makeObservable } from "mobx";
import Router from "next/router";
import UserStore from "./UserStore";
import i18n from "../core/i18n";

class NotificationStore {
    message = "";
    type = "";
    isError = false;

    constructor() {
        makeObservable(this, {
            message: observable,
            type: observable,
            isError: observable,
            reset: action.bound,
            processSuccess: action.bound,
            processWarning: action.bound,
            processException: action.bound
        });
    }

    reset() {
        this.message = "";
        this.type = "";
        this.isError = false;
    }

    processSuccess(message, type) {
        this.message = i18n.t(message);
        this.type = type;
        this.isError = false;
    }

    processWarning(message, type) {
        this.message = i18n.t(message);
        this.type = type;
        this.isError = true;
    }

    processException(error, type) {
    
        let messageKey = '';

        if (error.response && error.response.data) {
            const { message } = error.response.data;
    
            if (message) {
                messageKey = message;
            }
        } else if (error.message) {
            messageKey = error.message;
        }
    
        this.isError = true;
        this.message = i18n.t(messageKey);
        this.type = type;

        // Handling JWT token expiration or invalidation
        if (error.message === "Expired JWT Token" || error.message === "Invalid JWT Token") {
            UserStore.logout(Router);
            return;
        }
        
    }
}

export default new NotificationStore();
