import { makeAutoObservable, runInAction } from "mobx";
import moment from "moment-timezone";
import { message } from "antd";
import RootStore from "../RootStore";
import NotificationStore from "../NotificationStore";
import OrderRouteDecorator from "./OrderRouteDecorator";
import getPartners from "../../utils/ordersListStore/getPartners";
import getOrderRoutes from "../../utils/ordersListStore/getOrderRoutes";
import i18n from "../../core/i18n";

class OrdersListStore {
    isLoading = false;
    routes = [];
    orders = [];
    selectedOrdersHome = [];
    selectedRoutes = [];
    selectedOrders = [];
    isAllSelected = false;
    paginator = { page: 1, itemsPerPage: 10, itemsCount: 0 };
    order = null;
    user = null;

    filters = [
        { name: "userIds", value: [] },
        { name: "pickupDates", value: undefined },
    ];

    showPaymentModal = false;
    paymentRouteIds = null;
    paymentOrderId = null;
    haveToPayInAdvance = false;
    isContinueToPaymentModalOpened = false;
    redirectPayUrlMessage = "";
    showReviewExistModal = false;
    collectLatlng = [];

    query = {};
    onLoad = null;

    // Constants
    TYPE_TRANSFER = 1;
    TYPE_HOUR_RENTAL = 2;
    TYPE_EXCURSION = 3;
    STATUS_NOT_CONFIRMED = 1;
    STATUS_CONFIRMED = 2;
    STATUS_CANCELLED = 3;
    STATUS_DRIVER_NO_REACTION = 4;
    STATUS_DRIVER_NOT_CONFIRMED = 5;
    STATUS_DRIVER_CONFIRMED = 6;
    STORNO_STATUS_CUSTOMER_STORNO = 15;
    PAYMENT_STATUS_NOT_PAID = 24;
    PAYMENT_STATUS_PAID = 25;

    constructor() {
        makeAutoObservable(this);
    }

    setCallback(cb) {
        this.onLoad = cb;
    }

    clearCallback() {
        this.onLoad = null;
    }

    load(useLastQuery = false) {
        const query = {};

        this.isLoading = true;
        NotificationStore.reset();

        let queryTemp = query;

        if (!useLastQuery) {
            queryTemp.ignorePagination = false;
            queryTemp.page = this.paginator.page;
            queryTemp.itemsPerPage = this.paginator.itemsPerPage;

            if (this.filters.length) {
                const filters = {};

                this.filters.forEach((data) => {
                    filters[data.name] = data.value;
                });

                queryTemp.filters = JSON.stringify(filters);
            }
            this.query = queryTemp;
        } else {
            queryTemp = this.query;
        }

        RootStore.api.get("/order-routes/", queryTemp)
            .then((response) => {
                runInAction(() => {
                    this.isLoading = false;
                    this.paginator = response.paginator;

                    const orders = [];
                    this.selectedOrders = [];
                    this.selectedRoutes = [];
                    this.selectedOrdersHome = [];
                    this.isAllSelected = false;
                    this.routes = [];
                    response.items.forEach((o) => {
                        o.routes = o.routes.sort((a, b) => moment(a.pickupDate).unix() - moment(b.pickupDate).unix());
                        orders.push(o);
                        o.routes.forEach((r) => {
                            this.routes.push(new OrderRouteDecorator(r));
                        });
                    });
                    this.orders = orders;
                    if (this.onLoad) {
                        this.onLoad();
                        this.clearCallback();
                    }
                });
            })
            .catch((e) => {
                runInAction(() => {
                    this.isLoading = false;
                });
                NotificationStore.processException(e, "orders");
            });
    }

    loadOrdersByPartners(user) {
        let customers = getPartners(user).map(partner => partner.id);
        customers.push(user.id);
        this.setFilter("userIds", customers);
    }

    setFilter(name, value, load = true) {
        if (name) {
            const filterIndex = this.filters.findIndex(filter => filter.name === name);
            if (filterIndex > -1) {
                this.filters[filterIndex].value = value;
            } else {
                this.filters.push({ name, value });
            }
        } else {
            this.filters = [];
        }

        if (load) {
            this.paginator.page = 1;
            this.load();
        }
    }

    getFilterValue(name) {
        const filter = this.filters.find(filter => filter.name === name);
        return filter ? filter.value : undefined;
    }

    clearIsContinueToPaymentModalOpened() {
        this.isContinueToPaymentModalOpened = false;
    }

    clearRedirectPayUrlMessage() {
        this.redirectPayUrlMessage = "";
    }

    setIsContinueToPaymentModalOpened(isContinueToPaymentModalOpened) {
        this.isContinueToPaymentModalOpened = isContinueToPaymentModalOpened;
    }

    setRedirectPayUrlMessage(redirectPayUrlMessage) {
        this.redirectPayUrlMessage = redirectPayUrlMessage;
    }

    setShowReviewExistModal(showReviewExistModal) {
        this.showReviewExistModal = showReviewExistModal;
    }

    setShowPaymentModal(showPaymentModal) {
        this.showPaymentModal = showPaymentModal;
    }

    setPaymentRouteIds(paymentRouteIds) {
        this.paymentRouteIds = paymentRouteIds;
    }

    setPaymentOrderId(paymentOrderId) {
        this.paymentOrderId = paymentOrderId;
    }

    setHaveToPayInAdvance(haveToPayInAdvance) {
        this.haveToPayInAdvance = haveToPayInAdvance;
    }

    updateRoutesPaymentType(routeIds, paymentTypeId) {
        this.isLoading = true;
        RootStore.api.put(`/orders/updateRoutesPaymentType/${routeIds}/`, { paymentTypeId })
            .then(() => {
                runInAction(() => {
                    this.isLoading = false;
                    this.setShowPaymentModal(false);
                    this.load();
                    message.success(i18n.t("Objednávka byla úspěšně upravena", 3));
                });
            })
            .catch((e) => {
                runInAction(() => {
                    this.isLoading = false;
                    this.setShowPaymentModal(false);
                    message.error(i18n.t(e.message));
                });
            });
    }

    updateOrder(disableLoad = false, routes) {
        RootStore.api.put(`/orders/${this.order}/`, { customer: this.user.id, routes })
            .then(() => {
                runInAction(() => {
                    if (!disableLoad) {
                        this.isLoading = false;
                        this.load(true);
                    }
                    NotificationStore.processSuccess("Uloženo", "orders");
                });
            })
            .catch((e) => {
                runInAction(() => {
                    this.isLoading = false;
                });
                NotificationStore.processException(e, "orders");
            });
    }

    cancelRoute(routeId) {
        this.isLoading = routeId;
        RootStore.api.put(`/order-routes/${routeId}/cancel/`)
            .then(() => {
                runInAction(() => {
                    this.load(true);
                    message.success(i18n.t("Objednávka byla stornována"));
                });
            })
            .catch((e) => {
                runInAction(() => {
                    NotificationStore.processException(e, "orders");
                    this.isLoading = false;
                });
            })
            .finally(() => {
                runInAction(() => {
                    this.isLoading = false;
                });
            });
    }

    changePage(page, pageSize) {
        this.paginator.page = page;
        this.paginator.itemsPerPage = pageSize;
        this.isAllSelected = false;
        this.selectedOrdersHome = [];
        this.selectedRoutes = [];
        this.selectedOrders = [];
        window.scrollTo(0, 0);
        this.load();
    }

    loadOrderFromRoute(order) {
        runInAction(() => {
            this.order = order.order.id;
            this.user = order.order.customer;
            const routes = getOrderRoutes(order);
            this.populateRoute(routes);
        });
    }

    loadOrderFromRoutes(routes) {
        runInAction(() => {
            this.order = routes[0].order.id;
            this.user = routes[0].order.customer;

            const allRoutes = getOrderRoutes(routes[0]);
            routes.forEach((route) => {
                const routeFromAll = allRoutes.findIndex(allRoute => allRoute.id === route.id);
                if (routeFromAll > -1) {
                    allRoutes[routeFromAll] = route;
                }
            });
            this.populateRoute(allRoutes);
        });
    }

    populateRoute(data) {
        const routes = [];
        if (data !== null) {
            data.forEach((r) => {
                routes.push(new OrderRouteDecorator(r));
            });
        } else {
            routes.push(new OrderRouteDecorator());
        }
        this.routes = routes;
        return routes;
    }

    exportXls() {
        const ids = this.routes.map(route => route.id);
        RootStore.api.get("/order-routes/export-xls/", { ids })
            .then((response) => {
                const linkSource = `data:application/vnd.ms-excel;base64,${response}`;
                const downloadLink = document.createElement("a");

                downloadLink.href = linkSource;
                downloadLink.download = "export.xlsx";
                downloadLink.click();
            })
            .catch((e) => {
                NotificationStore.processException(e, "orders-exportXls");
            });
    }

    compareDate(date) {
        if (!date.isValid()) {
            return false; // Return false if the date is invalid
        }
        const currentDate = moment();
        const daysDifference = currentDate.diff(date, 'days'); // Difference in days
        return daysDifference <= 365 && daysDifference >= -365; // Check if within one year ahead and one year back
    }

    toggleSelectedAll(data) {
        if (!this.isAllSelected) {
            const selectedOrders = [];
            data.forEach((item) => {
                item.routes.forEach((route) => {
                    this.selectedRoutes.push(route.id);
                    selectedOrders.push(route.key);
                });
                this.selectedOrders.push(item.order.id);
            });
            this.isAllSelected = true;
            this.selectedOrdersHome = selectedOrders;
        } else {
            this.selectedOrders = [];
            this.selectedRoutes = [];
            this.selectedOrdersHome = [];
            this.isAllSelected = false;
        }
    }

    toggleSelected(index, order, routeId) {
        const position = this.selectedOrdersHome.indexOf(index);
        const orderPosition = this.selectedOrders.indexOf(order.order.id);
        const orderRoutes = order.routes;
        let isAllRouteUnselected = true;

        if (position > -1) {
            this.selectedOrdersHome.splice(position, 1);
            this.selectedRoutes.splice(position, 1);
            orderRoutes.forEach((route) => {
                const routePosition = this.selectedOrdersHome.indexOf(route.key);
                if (routePosition > -1) {
                    isAllRouteUnselected = false;
                }
            });
            if (isAllRouteUnselected) {
                this.selectedOrders.splice(orderPosition, 1);
            }
        } else {
            this.selectedOrdersHome.push(index);
            this.selectedRoutes.push(routeId);
            if (orderPosition <= -1) {
                this.selectedOrders.push(order.order.id);
            }
        }
    }

    toggleSelectedOrder(order) {
        const position = this.selectedOrders.indexOf(order.order.id);
        const orderRoutes = order.routes;
        if (position > -1) {
            this.selectedOrders.splice(position, 1);
            orderRoutes.forEach((route) => {
                const routePosition = this.selectedOrdersHome.indexOf(route.key);
                this.selectedOrdersHome.splice(routePosition, 1);
                this.selectedRoutes.splice(routePosition, 1);
            });
        } else {
            orderRoutes.forEach((route) => {
                const routePosition = this.selectedOrdersHome.indexOf(route.key);
                if (routePosition === -1) {
                    this.selectedOrdersHome.push(route.key);
                    this.selectedRoutes.push(route.id);
                }
            });
            this.selectedOrders.push(order.order.id);
        }
    }

    cancelOrder(orderId) {
        this.isLoading = true;
        RootStore.api.put(`/orders/cancel/${orderId}/`)
            .then(() => {
                runInAction(() => {
                    this.load(true);
                    message.success(i18n.t("Objednávka byla stornována"));
                });
            })
            .catch((e) => {
                runInAction(() => {
                    NotificationStore.processException(e, `order-${orderId}`);
                    this.isLoading = false;
                });
            })
            .finally(() => {
                runInAction(() => {
                    this.isLoading = false;
                });
            });
    }

    resetFilter(user) {
        runInAction(() => {
            this.filters = [];
            this.setFilter("pickupDates", undefined);
            this.loadOrdersByPartners(user);
        });
    }

    startDownloadOrder(orderId, fileName = "order.pdf") { // eslint-disable-line class-methods-use-this
        RootStore.api.get(`/orders/order-export/${orderId}/`)
            .then((response) => {
                const linkSource = `data:application/pdf;base64,${response}`;
                const downloadLink = document.createElement("a");

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            })
            .catch((e) => {
                NotificationStore.processException(e, "orders-downloadOrder");
            });
    }
}

export default new OrdersListStore();
