import {
    FacebookFilled,
    InstagramFilled,
    PhoneFilled,
    WhatsAppOutlined,
    XOutlined,
    YoutubeOutlined
} from "@ant-design/icons";
import i18n from "../core/i18n";

const Footer = () => {

      return (
        <footer className="footer">
            <div className="container">
                <div className="ant-row">
                    <div className="ant-col-md-24 footer__social-wrapper">
                        <ul className="footer__social">
                            <li className="footer__social-item">
                                <a
                                    href="tel:+420222554211"
                                    className="tooltips"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Call us"
                                >
                                    <PhoneFilled className="footer__social-item-icon" />
                                </a>
                            </li>
                            <li className="footer__social-item">
                                <a
                                    href="https://wa.me/447449011780"
                                    className="tooltips"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="WhatsApp"
                                >
                                    <WhatsAppOutlined className="footer__social-item-icon" />
                                </a>
                            </li>
                            <li className="footer__social-item">
                                <a
                                    href="https://www.facebook.com/prague.airport.transfers.sro"
                                    className="tooltips"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Facebook"
                                >
                                    <FacebookFilled className="footer__social-item-icon" />
                                </a>
                            </li>
                            <li className="footer__social-item">
                                <a
                                    href="https://www.instagram.com/pragueairportshuttle/"
                                    className="tooltips"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Instagram"
                                >
                                    <InstagramFilled className="footer__social-item-icon" />
                                </a>
                            </li>
                            <li className="footer__social-item">
                                <a
                                    href="https://twitter.com/PragueATransfer"
                                    className="tooltips"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Twitter"
                                >
                                    <XOutlined className="footer__social-item-icon" />
                                </a>
                            </li>
                            <li className="footer__social-item">
                                <a
                                    href="https://www.youtube.com/user/guidetoprague"
                                    className="tooltips"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Youtube"
                                >
                                    <YoutubeOutlined className="footer__social-item-icon" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="ant-row">
                    <div className="ant-col-md-24 footer__terms-wrapper">
                        <p className="footer__copyright">
                            ©® 2004-
                            {new Date().getFullYear()}
                            {" Prague Airport Transfers s.r.o. | "}
                            <a href={i18n.t("Smluvni podminky - url")}>
                                {i18n.t("Smluvní podmínky a garance")}
                            </a>
                            {" | "}
                            <a href={i18n.t("Kontaktujte nas - url")}>
                                {i18n.t("Kontaktujte nás")}
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
