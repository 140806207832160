import { observable, makeObservable } from "mobx";
import RootStore from "./RootStore";
import NewOrderStore from "./Order/NewOrderStore";

class FlightInformationStore {
    isLoading = false;
    flightInformation = [];
    isArrive = null;

    constructor() {
        makeObservable(this, {
            isLoading: observable,
            flightInformation: observable,
            isArrive: observable
        });
    }

    getFlightInformation(phrase, isArrive, ftr) {
        this.isLoading = true;
        let arrivaltime = null;

        if (ftr === 0) {
            if (NewOrderStore.pickupDate && phrase && phrase.length > 5) {
                if (typeof NewOrderStore.pickupDate.format === 'function') {
                    arrivaltime = NewOrderStore.pickupDate.format('YYYY-MM-DD');
                } else {
                    console.error("pickupDate does not have a format method.");
                }
            }
        }

        if (ftr === 1) {
            if (typeof NewOrderStore.returnPickupDate.format === 'function') {
                arrivaltime = NewOrderStore.returnPickupDate.format('YYYY-MM-DD');
            } else {
                console.error("returnPickupDate does not have a format method.");
            }
        }

        RootStore.api.get("/flight-information/", { phrase, isArrive, arrivaltime }).then((response) => {
            if (ftr === 0) {
                NewOrderStore.flightTimeToArrive[ftr] = response;
            }
            if (ftr === 1 && NewOrderStore.flightTimeToArrive && NewOrderStore.flightTimeToArrive.length > 0) {
                NewOrderStore.flightTimeToArrive[ftr] = response;
            }
            if (ftr === 1 && NewOrderStore.flightTimeToArrive && NewOrderStore.flightTimeToArrive.length === 0) {
                NewOrderStore.flightTimeToArrive[0] = null;
                NewOrderStore.flightTimeToArrive[ftr] = response;
            }

            this.flightInformation = response;
            this.isLoading = false;
        });
    }
}

export default new FlightInformationStore();
