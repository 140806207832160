import { observable, makeObservable } from "mobx";
import Router from "next/router";
import { message } from "antd";
import RootStore from "../RootStore";
import NotificationStore from "../NotificationStore";
import OrdersListStore from "../Order/OrdersListStore";
import { pushRoute }  from "../../components/Router.tsx";
import i18n from "../../core/i18n";
import PaymentStore from "./PaymentStore";
import CurrencyStore from "../Currency/CurrencyStore";
import UserStore from "../UserStore";
import AllowedTourType from "../../components/pages/Tours/detailsPage/BookingSection/AllowedTourType";
import { CookieStorage } from "../../models/CookieStorage";

class PayAllModalStore {
    isLoading = false;

    isModalOpened = false;

    routes = [];

    totalPaymentPrice = 0;

    selectedPaymentMethod = null;

    selectedCurrency = null;

    canBePayed = null;

    constructor() {
        makeObservable(this, {
            isLoading: observable,
            isModalOpened: observable,
            routes: observable,
            totalPaymentPrice: observable,
            selectedPaymentMethod: observable,
            selectedCurrency: observable,
            canBePayed: observable
        });
    }

    close() {
        this.isModalOpened = false;
        this.routes = [];
        this.totalPaymentPrice = 0;
        this.canBePayed = false;
    }

    setSelectedPaymentMethod(data, value, calledFromModal, showCount, paymentStatus) {
        const showBillingMethod = item => item.name !== PaymentStore.TYPE_BILLING_NAME
            || (item.name === PaymentStore.TYPE_BILLING_NAME && !calledFromModal);

        const notAllowedTypes = [];
        const allowedTypesWithSelectedCurrency = [];
        const allowedTypes = [];

        data.forEach((originalType) => {
            const type = { ...originalType };
            if (!AllowedTourType(type)) {
                notAllowedTypes.push(type);
            } else if (AllowedTourType(type) && !!type.selectedCurrencies.find(selectedCurrency => selectedCurrency.code === CurrencyStore.selectedCurrency)) {
                const canInvoice = CookieStorage.read("can_invoice");
                if (type.id === PaymentStore.TYPE_BILLING && UserStore && UserStore.user && canInvoice) {
                    allowedTypesWithSelectedCurrency.unshift(type);
                } else {
                    allowedTypesWithSelectedCurrency.push(type);
                }
                if (type.id === PaymentStore.TYPE_COMGATE) {
                    PaymentStore.moveObjectInArray(allowedTypesWithSelectedCurrency, PaymentStore.TYPE_COMGATE_NAME, PaymentStore.TYPE_PAYPAL_NAME);
                }
            } else {
                allowedTypes.push(type);
            }
        });

        const dataTemp = [
            ...allowedTypesWithSelectedCurrency,
            ...allowedTypes,
            ...notAllowedTypes,
        ];

        const itemData = dataTemp
            .filter(item => !paymentStatus || (paymentStatus === OrdersListStore.PAYMENT_STATUS_NOT_PAID)
                || (paymentStatus !== OrdersListStore.PAYMENT_STATUS_NOT_PAID && (item.id === value)))
            .filter(item => item.id !== PaymentStore.TYPE_PAY_LATER)
            .filter((item, index) => index < showCount && showBillingMethod(item) && item.inAdvance);

        if (itemData[0]) {
            this.selectedPaymentMethod = itemData[0].id;
        }
    }

    open(routesIds) {
        this.isModalOpened = true;
        if (Array.isArray(routesIds)) {
            this.routes = routesIds;
        } else {
            this.routes = [routesIds];
        }
        this.routes = OrdersListStore.routes.filter(route => routesIds.includes(route.id));
        this.getTotalPaymentPrice();
        this.canBePayed = this.hasValidDate();

        const route = this.routes.length === 1 ? this.routes[0] : null;
        if (route && route.arrear < route.totalPrice) {
            this.selectedPaymentMethod = route ? route.paymentType.id : null;
        } else {
            this.setSelectedPaymentMethod(PaymentStore.types, this.selectedPaymentMethod, true, 10, route ? route.paymentStatus.id : null);
        }
        this.selectedCurrency = localStorage.getItem("CurrencyStoreCode");
        // this.selectedCurrency = route ? route.paymentCurrency : null;
    }

    openTour(tourData) {
        this.isModalOpened = true;
        this.totalPaymentPrice = (tourData.totalPrice) ? tourData.totalPrice : 0;
        this.canBePayed = true;
        this.selectedCurrency = (tourData.paymentCurrency) ? tourData.paymentCurrency : null;
        this.selectedPaymentMethod = (tourData.paymentId) ? tourData.paymentId : null;
    }

    hasCarsZeroPrice() {
        this.routes.forEach((route) => {
            route.orderRouteCars.forEach((car) => {
                if (car.priceClient <= 0) {
                    return true;
                }
            });
        });
        return false;
    }

    payRoutes(router) {
        this.isLoading = true;
        const payableRoutes = this.routes.filter(route => route.arrear !== 0);
        const options = {
            routeIds: payableRoutes.map(route => (route.id)),
            paymentMethodId: this.selectedPaymentMethod,
            paymentCurrency: this.selectedCurrency,
        };

        RootStore.api.post("/payments/pay-routes-in-advance/", options).then((response) => {
            if (response.redirectPayUrl && response.redirectPayUrl !== "error") {
                window.location.replace(response.redirectPayUrl);
            } else {
                OrdersListStore.load();
                pushRoute("orders", { lang: Router.query.lang, path: "orders" });

                if (response.isProforma) {
                    message.warning(i18n.t("Na email byl zaslán zálohový daňový doklad, prosíme o provedení platby"));
                }

                if (response.redirectPayUrl && (response.redirectPayUrl === "error" || response.redirectPayUrl === "")) {
                    message.error(i18n.t("Vyskytla se chyba na platební bráně. Opakujte platbu později nebo zvolte jinou platební možnost"));
                }
            }
        }).catch((e) => {
            message.error(i18n.t("Vyskytla se chyba na platební bráně. Opakujte platbu později nebo zvolte jinou platební možnost"));
            NotificationStore.processException(e, "pay-all-modal");
        }).finally(() => {
            this.isLoading = false;
            this.close();
        });
    }

    getTotalPaymentPrice() {
        this.isLoading = true;
        const options = {
            routeIds: this.routes.map(route => (route.id ? route.id : route)),
        };

        RootStore.api.post("/payments/get-total-payment-price/", options).then((response) => {
            this.totalPaymentPrice = response;
        }).catch((e) => {
            NotificationStore.processException(e, "pay-all-modal");
        }).finally(() => {
            this.isLoading = false;
        });
    }


    hasValidDate() {
        //const currentTime = moment();
        const canBePayed = true;
        this.routes.forEach((route) => {
            const routeDate = route.pickupDate.split("+")[0];
            // Customer can now pay anytime
            // if (currentTime.isBetween(moment(routeDate).subtract(SettingsStore.settings.hoursPaymentInAdvance, "h"), moment(routeDate))) {
            //    canBePayed = false;
            //    return;
            // }
        });
        return canBePayed;
    }
}

export default new PayAllModalStore();
