import { observable, makeObservable } from "mobx";

export default class TourBookingDecorator {
    id = null;

    isLoading = false;
    action = {
        isChecked:false 
    };
    
    adultCount = 1;
    childrenCount = 0;
    seniorsAwardCount = 0;
    youngerCount = 0;
    paymentStatus = null;
    bookingDate = null;
    paymentCurrency = null;
    status = null;
    
    conditionsApproved = true;
    creator = {};
    tour = {};
    totalPrice = 0;
    tourName = null;
    user = {};
    payments = [];
    paymentType = null;
    bookingTime = null;
    
    
    constructor(data) {
        makeObservable(this, {
            isLoading: observable,
            action: observable,
            adultCount: observable,
            childrenCount: observable,
            seniorsAwardCount: observable,
            youngerCount: observable,
            paymentStatus: observable,
            bookingDate: observable,
            paymentCurrency: observable,
            status: observable,
            conditionsApproved: observable,
            creator: observable,
            tour: observable,
            totalPrice: observable,
            tourName: observable,
            user: observable,
            payments: observable,
            paymentType: observable,
            bookingTime: observable
        });

        if (data) {
            this.setAll(data);
        }
    }
    Capitalize(str){
        return str.charAt(0).toUpperCase() + str.slice(1);
        }

    setAll(data) {
        this.id = data.id || null;
        this.adultCount = data.adultCount || null;
        this.childrenCount = data.childrenCount || null;
        this.seniorCount = data.seniorCount || null;
        this.infantCount = data.infantCount || null;
        this.paymentStatus = data.paymentStatus || null;
        this.bookingDate = data.bookingDate || null;
        this.creator = data.creator || null;
        this.tour = data.tour || null;
        this.tourName = data.tourName || null;
        this.user = data.user || null;
        this.totalPrice = data.totalPrice || null;
        this.paymentCurrency = data.paymentCurrency || null;
        this.status = data.status || null;
        this.paymentId = parseInt(data.paymentId) || null;
        this.payments=data.payments|| [];
        this.paymentType=data.paymentType || null;
        this.bookingTime=data.bookingTime || '12:15';
        this.noteForDriver=data.noteForDriver || null;
        this.totalPriceInPaymentCurrency=data.totalPriceInPaymentCurrency || 0;
        
    }
}
