import { makeAutoObservable, runInAction } from "mobx";
import { message } from "antd";
import RootStore from "../RootStore";
import NotificationStore from "../NotificationStore";
import TourBookingDecorator from "./TourBookingDecorator";
import i18n from "../../core/i18n";
import LanguageStore from "../Language/LanguageStore";

class TourOrderList {
    // Observables
    isLoading = false;
    tours = [];
    paginator = { page: 1, itemsPerPage: 10, itemsCount: 0 };
    selectedOrders = [];

    onLoad = null;

    showPaymentModal = false;

    paymentTourIds = null;

    haveToPayInAdvance = false;

    isContinueToPaymentModalOpened = false;

    redirectPayUrlMessage = "";

    constructor() {
        makeAutoObservable(this);
    }

    // Actions

    setCallback = (cb) => {
        this.onLoad = cb;
    }

    clearCallback = () => {
        this.onLoad = null;
    }

    clearRedirectPayUrlMessage = () => {
        this.redirectPayUrlMessage = "";
    }

    setRedirectPayUrlMessage = (redirectPayUrlMessage) => {
        this.redirectPayUrlMessage = redirectPayUrlMessage;
    }

    setShowPaymentModal = (showPaymentModal) => {
        this.showPaymentModal = showPaymentModal;
    }

    setPaymentTourIds = (paymentTourIds) => {
        this.paymentTourIds = paymentTourIds;
    }

    setHaveToPayInAdvance = (haveToPayInAdvance) => {
        this.haveToPayInAdvance = haveToPayInAdvance;
    }

    setIsContinueToPaymentModalOpened = (isContinueToPaymentModalOpened) => {
        this.isContinueToPaymentModalOpened = isContinueToPaymentModalOpened;
    }

    clearIsContinueToPaymentModalOpened = () => {
        this.isContinueToPaymentModalOpened = false;
    }

    load = async (useLastQuery = false) => {
        this.isLoading = true;
        NotificationStore.reset();

        let queryTemp = {};

        if (!useLastQuery) {
            queryTemp = {
                ignorePagination: false,
                page: this.paginator.page,
                itemsPerPage: this.paginator.itemsPerPage,
            };
            this.query = queryTemp;
        } else {
            queryTemp = this.query;
        }

        try {
            const response = await RootStore.api.get("/tourOrders/", queryTemp);
            runInAction(() => {
                this.isLoading = false;
                this.paginator = response.paginator;
                this.tours = response.items.map(o => new TourBookingDecorator(this.updateLanguageText(o, LanguageStore.lang)));

                if (this.onLoad) {
                    this.onLoad();
                    this.clearCallback();
                }
            });
        } catch (e) {
            runInAction(() => {
                this.isLoading = false;
            });
            NotificationStore.processException(e, "tours");
        }
    }

    startDownloadOrder = async (orderId, fileName = "order.pdf") => {
        try {
            const response = await RootStore.api.get(`/tourOrders/order-export/${orderId}/`);
            const linkSource = `data:application/pdf;base64,${response}`;
            const downloadLink = document.createElement("a");

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        } catch (e) {
            NotificationStore.processException(e, "tours");
        }
    }

    cancelTour = async (routeId) => {
        this.isLoading = routeId;
        try {
            await RootStore.api.put(`/tourOrders/${routeId}/cancel/`);
            runInAction(() => {
                message.success(i18n.t("Objednávka byla stornována"));
                this.load(true);
            });
        } catch (e) {
            message.error(i18n.t(e.message));
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    // Utility Methods

    updateLanguageText = (currentTour, lang) => {
        const found = currentTour.tour.tourTranslations.find(obj => obj.lang === lang);

        if (found) {
            currentTour.tourName = found.name;
            currentTour.shortDescription = found.shortDescription;
            currentTour.longDescription = found.longDescription;
            currentTour.includesDescription = found.includesDescription;
            currentTour.excludesDescription = found.excludesDescription;
            currentTour.cancellation = found.cancellation;
            currentTour.durationTime = found.durationTime;
            currentTour.meetingPoint = found.meetingPoint;
            currentTour.tourType = found.tourType;
        }

        return currentTour;
    }

    changePage = (page, pageSize) => {
        this.paginator.page = page;
        this.paginator.itemsPerPage = pageSize;
        window.scrollTo(0, 0);
        this.load();
    }
}

export default new TourOrderList();
