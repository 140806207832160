class SklikManager {
    conversionConf = () => {};

    init() {
        this.conversionConf = {
            id: 100003344,
            value: null,
        };
    }

    orderSubmit() {
        if (window.rc && window.rc.conversionHit) {
            window.rc.conversionHit(this.conversionConf);
        }
    }
}
export default new SklikManager();
