import { observable, makeObservable } from "mobx";
import { message } from "antd";
import RootStore from "../RootStore";
import NotificationStore from "../NotificationStore";
import i18n from "../../core/i18n";
import OrdersListStore from "./OrdersListStore";

class AddReviewModalStore {
    isLoading = false;

    isModalOpened = false;

    email = null;

    orderRouteId = null;

    emailType = null;

    constructor() {
        makeObservable(this, {
            isLoading: observable,
            isModalOpened: observable,
            email: observable,
            orderRouteId: observable,
            emailType: observable
        });
    }

    reset() {
        this.isLoading = false;
        this.isModalOpened = false;
    }

    open(orderRouteId) {
        this.orderRouteId = orderRouteId;
        NotificationStore.reset();
        this.isModalOpened = true;
    }

    close() {
        this.reset();
    }


    addReview(values) {
        this.isLoading = true;
        NotificationStore.reset();
        this.isLoading = this.orderRouteId;
        const data = {
            review: values.review,
            comment: values.comment,
            orderRouteId: this.orderRouteId,
        };

        RootStore.api.post("/order-routes/add-review/", data).then(() => {
            this.close();
            this.isLoading = false;
            OrdersListStore.load(true);
            message.success(i18n.t("Thank you for your review", 3));
        }).catch((e) => {
            this.isLoading = false;
            message.error(i18n.t(e.message));
        });
    }

    routeHasReview = orderRouteId => new Promise((resolve) => {
        const routeHasReview = RootStore.api.get(`/order-routes/route-has-review/${orderRouteId}/`);
        resolve(routeHasReview);
    })

    sendOrderEmail(values) {
        this.isLoading = true;
        NotificationStore.reset();

        const data = {
            email: values.email,
        };

        RootStore.api.post(`/orders/send-confirm-email/${this.orderRouteId}/`, data).then(() => {
            this.close();

            this.isLoading = false;
            NotificationStore.processSuccess(i18n.t("email úspěšně odeslán"), "send-confirmation-email");
        }).catch((e) => {
            this.isLoading = false;
            NotificationStore.processException(e, "send-confirmation-email");
        });
    }
}

export default new AddReviewModalStore();
