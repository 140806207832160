import CarCategoryStore from "../CarCategory/CarCategoryStore";
import i18n from "../../core/i18n";
import FlightInformationStore from "../FlightInformationStore";

class ZoneStore {
    TYPE_AIRPORT_FROM = 0;

    TYPE_AIRPORT_TO = 1;

    TYPE_TRAIN_FROM = 2;

    TYPE_TRAIN_TO = 3;

    TYPE_LONG = 4;

    TYPE_PRAGUE = 5;

    TYPE_OTHER = 6;

    TYPE_BUS_FROM = 7;

    TYPE_BUS_TO = 8;

    zoneDetailTypes = ["z letiště", "na letiště", "z nádraží", "na nádraží", "dálka", "Praha", "ostatní", "autobus"];

    isAirport(address) { // eslint-disable-line class-methods-use-this
        const airportAddresses = [
            "Terminál 1 - Odlety, 161 00 Praha 6, Česko",
            "Terminál 2 - Odlety, 161 00 Praha 6, Česko",
            "Aviatická, 161 08 Praha 6, Česko",
        ];

        return airportAddresses.includes(address);
    }

    isTypeAirportFrom = (returnRouteMode = false) => {
        const zoneKey = returnRouteMode ? "returnZoneInfo" : "zoneInfo";

        if (CarCategoryStore[zoneKey] !== null) {
            if (CarCategoryStore[zoneKey] === this.TYPE_AIRPORT_FROM) {
                return true;
            }
        }

        return false;
    }


    isTypeAirportTo = (returnRouteMode = false) => {
        const zoneKey = returnRouteMode ? "returnZoneInfo" : "zoneInfo";

        if (CarCategoryStore[zoneKey] !== null) {
            if (CarCategoryStore[zoneKey] === this.TYPE_AIRPORT_TO) {
                return true;
            }
        }

        return false;
    }

    getType = (returnRouteMode = false) => {
        const zoneKey = returnRouteMode ? "returnZoneInfo" : "zoneInfo";

        return CarCategoryStore[zoneKey];
    }

    getFlightNumberLabel(returnRouteMode = false) { // eslint-disable-line class-methods-use-this
        let flightNumberLabel = "";
        const zoneKey = returnRouteMode ? "returnZoneInfo" : "zoneInfo";

        if (CarCategoryStore[zoneKey] !== null) {
            if (CarCategoryStore[zoneKey] === this.TYPE_AIRPORT_FROM) {
                flightNumberLabel = i18n.t("Číslo příletu");
                FlightInformationStore.isArrive = 1;
            } else if (CarCategoryStore[zoneKey] === this.TYPE_AIRPORT_TO) {
                flightNumberLabel = i18n.t("Číslo odletu");
                FlightInformationStore.isArrive = 0;
            }
        }

        return flightNumberLabel;
    }

    getFlightNumberType(returnRouteMode = false) { // eslint-disable-line class-methods-use-this
        let flightNumberType = false;
        const zoneKey = returnRouteMode ? "returnZoneInfo" : "zoneInfo";

        if (CarCategoryStore[zoneKey] !== null) {
            if (CarCategoryStore[zoneKey] === this.TYPE_AIRPORT_FROM) {
                flightNumberType = true;
            } else if (CarCategoryStore[zoneKey] === this.TYPE_AIRPORT_TO) {
                flightNumberType = false;
            }
        }

        return flightNumberType;
    }
}

export default new ZoneStore();
