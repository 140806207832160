import getConfig from "next/config";
import Router from "next/router";
import fetch from "isomorphic-fetch";
import https from "https";
import UserStore from "../stores/UserStore";
import { CookieStorage } from "../models/CookieStorage";

export default class Api {
    constructor() {
        this.config = getConfig().publicRuntimeConfig;
    }

    get(endpoint, queryParams = {}) {
        const agent = new https.Agent({
            rejectUnauthorized: false,
        });

        const ops = {
            agent,
            method: "GET",
            headers: this.getHeaders(),
        };

        if (this.config.apiUrl.indexOf("https") === -1) {
            delete ops.agent;
        }

        let url = `${this.config.apiUrlPath}${endpoint}`;
        if (Object.keys(queryParams).length > 0) {
            url += (url.indexOf("?") === -1 ? "?" : "&") + this.queryParams(queryParams);
        }
        return this.fetchJson(url, ops);
    }

    post(endpoint, data = {}) {
        const ops = {
            method: "POST",
            headers: this.getHeaders(data),
            body: this.getData(data),
        };

        return this.fetchJson(`${this.config.apiUrlPath}${endpoint}`, ops);
    }

    renewToken(endpoint, data = {}) {
        const ops = {
            method: "POST",
            headers: this.getHeaders(data),
            body: this.getData(data),
        };

        return this.fetchJson(`/api${endpoint}`, ops);
    }

    login(endpoint, data = {}) {
        const ops = {
            method: "POST",
            headers: this.getHeaders(data),
            body: this.getData(data),
        };

        return this.fetchJson(`/api${endpoint}`, ops);
    }

    logout(endpoint, data = {}) {
        const ops = {
            method: "GET",
            headers: this.getHeaders(data),
        };

        return this.fetchJson(`/api${endpoint}`, ops);
    }

    put(endpoint, data = {}) {
        const ops = {
            method: "PUT",
            headers: this.getHeaders(data),
            body: this.getData(data),
        };

        return this.fetchJson(`${this.config.apiUrlPath}${endpoint}`, ops);
    }

    remove(endpoint) {
        const ops = {
            method: "DELETE",
            headers: this.getHeaders(),
        };
        return this.fetchJson(`${this.config.apiUrlPath}${endpoint}`, ops);
    }

    fetchJson(endpoint, options = {}) { // eslint-disable-line class-methods-use-this
        let url = `${this.config.apiUrl}${endpoint}`;
        // if running in docker, do requests to test
        if (this.config.runInDocker === true && (endpoint.includes("/api/booking/init") || endpoint.includes("/api/booking/currencies/"))) {
            url = `http://api-test.prague-airport-transfers.co.uk${endpoint}`;
        }

        return new Promise((resolve, reject) => {
            fetch(url, options).then((result) => {
                if (result.status === 200) {
                    result.json().then(json => resolve(json));
                } else {
                    result.json().then((json) => {
                        if ((json.message && json.message === "Expired JWT Token")
                            || json.message === "Invalid JWT Token"
                            || json.message === "JWT Token not found") {
                            UserStore.logout(Router, true);
                            return;
                        }
                        reject(json);
                    });
                }
            });
        });
    }

    getHeaders(data) { // eslint-disable-line class-methods-use-this
        const headers = {};

        const token = CookieStorage.read("token");
        if (token) {
            headers.Authorization = `Bearer ${token}`;
        }

        if (typeof window !== "undefined" && data instanceof FormData) {
            return headers; // multipart/form-data
        }

        headers["Content-Type"] = "application/json";
        return headers;
    }

    getData(data) { // eslint-disable-line class-methods-use-this
        if (data instanceof FormData) {
            return data;
        }

        return JSON.stringify(data);
    }

    queryParams(params) { // eslint-disable-line class-methods-use-this
        return Object.keys(params)
            .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
            .join("&");
    }
}
