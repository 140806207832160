import React, { Component } from "react";
import { Dropdown } from "antd";
import { observer } from "mobx-react";
import {
    DownOutlined,
} from "@ant-design/icons";
import { toJS } from "mobx";
import i18n from "../../core/i18n";
import CurrencyStore from "../../stores/Currency/CurrencyStore";

class CurrencyMenu extends Component {
    componentDidMount() {
        const CurrencyStoreCode = localStorage.getItem("CurrencyStoreCode");
        if (CurrencyStoreCode) {
            CurrencyStore.selectCurrency(CurrencyStoreCode);
            CurrencyStore.selectTourCurrency(CurrencyStoreCode);
        }
    }

    render() {
        let items = [];
        if (CurrencyStore.menuCurrencies) {
            items = Object.values(toJS(CurrencyStore.menuCurrencies));
        }

        const onClick = ({ key }) => {
            document.body.removeAttribute("style");
            CurrencyStore.selectCurrency(key);
            CurrencyStore.selectTourCurrency(key);
            localStorage.setItem("CurrencyStoreCode", key);
        };

        return (
            <div className="settings-menu settings-menu--currency">
                <span style={{ marginLeft: "10px", marginRight: "10px" }}>
                    {i18n.t("Měna")}
                </span>
                <Dropdown
                    menu={{
                        items,
                        onClick,
                    }}
                    onOpenChange={(open) => {
                        const body = document.body;
                        if (open) {
                            // Store the scroll position
                            body.style.top = `-${window.scrollY}px`;
                            body.classList.add('fixed-position');
                        } else {
                            const scrollY = body.style.top;
                            body.classList.remove('fixed-position');
                            window.scrollTo(0, parseInt(scrollY || '0') * -1);
                        }
                    }}
                    trigger={["click"]}
                >
                    <a onClick={e => e.preventDefault()}>
                        {i18n.t(CurrencyStore.selectedCurrency)}
                        {" "}
                        <DownOutlined />
                    </a>
                </Dropdown>
            </div>
        );
    }
}

export default observer(CurrencyMenu);
