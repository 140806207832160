import React, { Component } from "react";
import { Button } from "antd";
import { observer } from "mobx-react";
import { withRouter } from "next/router";
import { Link } from "../Router.tsx";
import i18n from "../../core/i18n";
import UserStore from "../../stores/UserStore";
import NewOrderStore from "../../stores/Order/NewOrderStore";

class UserMenu extends Component {
    render() {
        const { router } = this.props;

        return (
            <>
                {!UserStore.user && (
                    <>
                        <Link route="login" params={{ lang: router.query.lang, path: "login" }}>
                            <a>
                                <Button type="primary">
                                    {i18n.t("Přihlásit se")}
                                </Button>
                            </a>
                        </Link>
                        <Link route="index" params={{ lang: router.query.lang }}>
                            <a>
                                <Button onClick={() => { NewOrderStore.showRegistrationInfo = true; }} type="primary">
                                    {i18n.t("Registrace")}
                                </Button>
                            </a>
                        </Link>
                    </>
                )}
                {UserStore.user && (
                    <>
                        <Link route="login" params={{ lang: router.query.lang, path: "login" }}>
                            <a>
                                <Button
                                    onClick={() => {
                                        UserStore.logout(router);
                                    }}
                                >
                                    {i18n.t("Odhlásit se")}
                                </Button>
                            </a>
                        </Link>
                    </>
                )}
            </>
        );
    }
}

export default withRouter(observer(UserMenu)); 