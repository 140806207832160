export default function getPartners(user) {
    const partners = [];
    if (!user) {
        return partners;
    }
    if (user.partners) {
        user.partners.forEach((partner) => {
            partners.push(partner);
        });
    }
    return partners;
}
