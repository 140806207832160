import { observable, makeObservable } from "mobx";
import RootStore from "../RootStore";
import NotificationStore from "../NotificationStore";
import TourDecorator from "./TourDecorator";
import i18n from "../../core/i18n";
import LanguageStore from "../Language/LanguageStore";

class TourListStore {
    isLoading = false;

    selectBoxMessage = "";

    tours = [];

    paginator = { page: 1, itemsPerPage: 12, itemsCount: 0 };

    tour = null;

    slug = null;

    slugcollection = [];

    query = {};

    defaultImagePath="/img/noimg.png";

    myRef = {};

    isDropDownSelected = false;

    adultCount = 1;

    childrenCount = 0;

    seniorsAwardCount = 0;

    youngerCount = 0;

    paymentType = true;

    adultPrice = 0;

    childrenPrice = 0;

    seniorsAwardPrice = 0;

    youngerPrice = 0;

    constructor() {
        makeObservable(this, {
            isLoading: observable,
            selectBoxMessage: observable,
            tours: observable,
            paginator: observable,
            tour: observable,
            slug: observable,
            slugcollection: observable,
            isDropDownSelected: observable,
            adultCount: observable,
            childrenCount: observable,
            seniorsAwardCount: observable,
            youngerCount: observable,
            paymentType: observable,
            adultPrice: observable,
            childrenPrice: observable,
            seniorsAwardPrice: observable,
            youngerPrice: observable
        });
    }

    getBookingInfo() {
        return {
            adult_count: this.adultCount,
            children_count: this.childrenCount,
            infant_count: this.youngerCount,
            senior_count: this.seniorsAwardCount,
            person_group_count: 1,
        };
    }

    changePage(page,pageSize) {
        this.paginator.page = page;
        this.paginator.itemsPerPage = pageSize;
        window.scrollTo(0, 0);
        this.load();
    }

    calculatePrice() {
        if (this.tour && this.tour.pricingType == "per person") {
            this.adultPrice = (this.tour.adultPrice > 0 && this.adultCount > 0) ? this.tour.adultPrice * this.adultCount : 0;


            this.childrenPrice = (this.tour.childrenPrice > 0 && this.childrenCount > 0) ? this.tour.childrenPrice * this.childrenCount : 0;
            this.seniorsAwardPrice = (this.tour.seniorPrice > 0 && this.seniorsAwardCount > 0) ? this.tour.seniorPrice * this.seniorsAwardCount : 0;
            this.youngerPrice = (this.tour.infantPrice > 0 && this.youngerCount > 0) ? this.tour.infantPrice * this.youngerCount : 0;
        } else {
            const totalCount = this.adultCount + this.childrenCount + this.youngerCount + this.seniorsAwardCount;
            let getPrice = 0;
            if (totalCount <= 1) {
                getPrice = this.tour.personGroup;
            } else if (totalCount <= 3) {
                getPrice = Math.round(this.tour.personGroup2 / totalCount);
            } else if (totalCount <= 7) {
                getPrice = Math.round(this.tour.personGroup3 / totalCount);
            } else if (totalCount <= 19) {
                getPrice = Math.round(this.tour.personGroup4 / totalCount);
            }
            this.adultPrice = (getPrice > 0 && this.adultCount > 0) ? getPrice * this.adultCount : 0;
            this.childrenPrice = (getPrice > 0 && this.childrenCount > 0) ? getPrice * this.childrenCount : 0;
            this.seniorsAwardPrice = (getPrice > 0 && this.seniorsAwardCount > 0) ? getPrice * this.seniorsAwardCount : 0;
            this.youngerPrice = (getPrice > 0 && this.youngerCount > 0) ? getPrice * this.youngerCount : 0;
        }
    }

    displayPrice(tour) {
        if (tour.pricingType == "per person") {
            return (tour.adultPrice > 0) ? tour.adultPrice : 0;
        }
        return (tour.personGroup > 0) ? tour.personGroup : 0;
    }

    getTotalPrice() {
        return this.adultPrice + this.childrenPrice + this.seniorsAwardPrice + this.youngerPrice;
    }

    participantSelectionText() {
        let collectString = "";

        if (this.adultCount) {
            collectString += `${i18n.t("Adult")} X ${this.adultCount} `;
        }
        if (this.childrenCount) {
            collectString += `${i18n.t("Children")} X ${this.childrenCount} `;
        }
        if (this.youngerCount) {
            collectString += `${i18n.t("Younger children")} X ${this.youngerCount} `;
        }
        if (this.seniorsAwardCount) {
            collectString += `${i18n.t("Senior")} X ${this.seniorsAwardCount} `;
        }
        if (!this.adultCount && !this.childrenCount && !this.youngerCount && !this.seniorsAwardCount) {
            collectString += i18n.t("Select participants");
        }

        return collectString;
    }

    checkIfZeroSelected() {
        if (!this.adultCount && !this.childrenCount && !this.youngerCount && !this.seniorsAwardCount) {
            return true;
        } if (!this.adultCount && !this.seniorsAwardCount && (this.childrenCount > 0 || this.youngerCount) && this.tour.pricingType === "per group") {
            return true;
        }
        return false;
    }

    checkAllowedP(){
        let totalMembers=this.adultCount+this.seniorsAwardCount+this.childrenCount+this.youngerCount; 
        if (totalMembers>19 && this.tour.pricingType === "per group") {
            return true;
        }
        return false;
    }
    getSelectMessage() {
        if (!this.adultCount && !this.childrenCount && !this.youngerCount && !this.seniorsAwardCount) {
            return i18n.t("Please select at least one");
        } if (!this.adultCount && !this.seniorsAwardCount && (this.childrenCount > 0 || this.youngerCount) && this.tour.pricingType === "per group") {
            return i18n.t("Please least one Adult or Senior");
        }
        let totalMembers=this.adultCount+this.seniorsAwardCount+this.childrenCount+this.youngerCount;
       // console.log("totalMembers",totalMembers);
        if (totalMembers>19 && this.tour.pricingType === "per group") {
            return i18n.t("Max. allowed group is for 19pax.");
        }

        return "";
    }


    onLoad = null;

    setCallback(cb) {
        this.onLoad = cb;
    }

    clearCallback() {
        this.onLoad = null;
    }

    loadPageByslug(lang) {
        this.tour = null;
        const query = {};

        this.isLoading = true;
        NotificationStore.reset();
        query.slug = this.slug;
        query.lang = lang;
        const queryTemp = query;
        this.slugcollection=[];
        RootStore.api.get("/tours/slug", queryTemp).then((response) => {
            this.isLoading = false;
            this.slugcollection["en"] = response.slug;
            if (response.tourTranslations) {
                response.tourTranslations.forEach((elm) => {
                    this.slugcollection[elm.lang] = elm.slug;
                });
            }
            
            const arr = this.updateLanagugeText(response, LanguageStore.lang);
            this.tour = new TourDecorator(arr);
        }).catch((e) => {
            this.isLoading = false;
            NotificationStore.processException(e, "tours");
        });
    }


    updateLanagugeText(currentTour, lang) {
        const found = currentTour.tourTranslations.find(obj => obj.lang === lang);

        if (found) {
            currentTour.slug = found.slug;
            currentTour.name = found.name;
            currentTour.shortDescription = found.shortDescription;
            currentTour.longDescription = found.longDescription;
            currentTour.includesDescription = found.includesDescription;
            currentTour.excludesDescription = found.excludesDescription;
            currentTour.cancellation= found.cancellation;
            currentTour.durationTime = found.durationTime;
            currentTour.meetingPoint = found.meetingPoint;
            currentTour.tourType = found.tourType;
        }
        return currentTour;
    }

    load(useLastQuery = false) {
        const query = {};

        this.isLoading = true;
        NotificationStore.reset();

        let queryTemp = query;

        if (!useLastQuery) {
            queryTemp.ignorePagination = false;
            queryTemp.page = this.paginator.page;
            queryTemp.itemsPerPage = this.paginator.itemsPerPage;
            this.query = queryTemp;
        } else {
            queryTemp = this.query;
        }


        RootStore.api.get("/tours/", queryTemp).then((response) => {
            this.isLoading = false;
            this.paginator = response.paginator;
            this.tours = [];
            response.items.forEach((o) => {
                const arr = this.updateLanagugeText(o, LanguageStore.lang);
                this.tours.push(new TourDecorator(arr));
            });

            if (this.onLoad) {
                this.onLoad();
                this.clearCallback();
            }
        }).catch((e) => {
            this.isLoading = false;
            NotificationStore.processException(e, "tours");
        });
    }

    TourNewBooking(values) {
        console.log("Booking Values", values);
    }
}

export default new TourListStore();
