import nookies from "nookies";

export class CookieStorage {
    static getOptions(ctx) {
        const isProduction = process.env.NODE_ENV === 'production';
        return {
            path: "/",
            ...(isProduction && { secure: true }),
            sameSite: 'Lax',
        };
    }

    static read(key, ctx = null) {
        const cookies = nookies.get(ctx);
        return cookies[key] || null;
    }

    static write(key, value, ctx = null) {
        nookies.set(ctx, key, value, this.getOptions(ctx));
    }

    static destroy(key, ctx = null) {
        nookies.destroy(ctx, key, this.getOptions(ctx));
    }
}
