import React from "react";
import { EventChangeOptions } from "next/router";
import { Link as RoutesLink, Router as RoutesRouter } from "../../routes";
import LanguageStore from "../stores/Language/LanguageStore";

interface Params {
    lang?: string;
    path?: string;
    slug?: string;
}

interface LinkProps {
    route: string;
    params: Params;
    children: JSX.Element;
}

const translatePath = (params: Params): Params => {
    if (params.path) {
        let translatedPath = LanguageStore.getPageTranslate(params.lang || LanguageStore.lang, `page-${params.path}`);
        if (!translatedPath) {
            translatedPath = LanguageStore.getPageTranslate("en", `page-${params.path}`);
        }

        return {
            ...params,
            lang: params.lang || LanguageStore.lang,
            path: params.path === "index" ? "" : translatedPath,
        };
    }

    return {
        ...params,
        lang: params.lang || LanguageStore.lang,
    };
};

export const Link = ({ route, params, children }: LinkProps) => {
    const newParams = translatePath(params);

    return (
        <RoutesLink route={route} params={newParams}>{children}</RoutesLink>
    );
};

export const pushRoute = (route: string, params: Params, options: EventChangeOptions = {}) => {
    const newParams = translatePath(params);
    RoutesRouter.pushRoute(route, newParams, options);
};

export const dynamicRoutes = (params) => {
    const newParams = translatePath(params);
    return `${newParams.lang}`.replace(/\/+/g, '/');
};

export const tourDynamicRoutes = ({ lang, path }) => {
    const newParams = translatePath({ lang, path });
    return {
      pathname: '/[lang]/[slug]',
      query: { lang: newParams.lang, slug: newParams.path },
    };
  };

export const windowLocation = (route: string, params: Params, isNewslug = false) => {
    const newParams = translatePath(params);

    if (params.slug) {
        let newPath = "";
        if (isNewslug) {
            newPath = params.path.replace("[slug]", isNewslug);
        } else {
            newPath = params.path.replace("[slug]", params.slug);
        }

        window.location.replace(`/${newParams.lang}/${newPath}`);
    } else {
        window.location.replace(`/${newParams.lang}/${newParams.path}`);
    }
};
