import React, { Component } from "react";
import { Dropdown, Drawer, Button } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";
import { withRouter } from "next/router";
import LanguageStore from "../../stores/Language/LanguageStore";
import i18n from "../../core/i18n";
import { windowLocation } from "../Router.tsx";
import UserListStore from "../../stores/User/UserListStore";
import TourListStore from "../../stores/Tours/TourListStore";

class LanguagesMenu extends Component {
    state = {
        isMobile: false,
        drawerVisible: false,
    };

    componentDidMount() {
        this.handleResize();
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = () => {
        this.setState({ isMobile: window.innerWidth <= 1024 });
    };

    showDrawer = () => {
        this.setState({ drawerVisible: true });
    };

    closeDrawer = () => {
        this.setState({ drawerVisible: false });
    };

    render() {
        const { router } = this.props;
        const { isMobile, drawerVisible } = this.state;

        // Move 'en' to the top of the languages list
        const sortedLanguages = [
            ...LanguageStore.languages.filter(lang => lang.language === "en"),
            ...LanguageStore.languages.filter(lang => lang.language !== "en"),
        ];

        // Create dropdown items with 'cs' mapped from 'cz'
        let items = [];
        sortedLanguages.forEach((language, i) => {
            const displayKey = language.language === "cz" ? "cs" : language.language;
            const imageSource = `/img/flags/${displayKey}.png`;
            items[i] = {
                key: displayKey, // Use 'cs' instead of 'cz'
                label: language.nativeName,
                icon: <img src={imageSource} alt={displayKey} width="20" />,
            };
        });

        const onClick = ({ key }) => {
            const languageKey = key; // 'cs' is used directly

            // Save the selected language
            UserListStore.save({ language: languageKey });

            // Remove any inline styles if previously added
            document.body.removeAttribute("style");

            // Handle routing with the selected language
            const { slugcollection } = TourListStore;
            let route = "index";
            let urd = "";
            if (router.route.includes("/[lang]/")) {
                route = router.route.replace("/[lang]/", "");
            }
            if (slugcollection && slugcollection[languageKey]) {
                urd = slugcollection[languageKey];
            }

            // Update the URL with the 'cs' language code
            windowLocation(route, { ...router.query, lang: languageKey, path: route }, urd);

            // Close the drawer if it's open
            this.closeDrawer();
        };

        // Drawer content without search functionality
        const drawerContent = (
            <div style={{ padding: "20px", textAlign: "center" }}>
                {sortedLanguages.map((language) => {
                    const displayKey = language.language === "cz" ? "cs" : language.language;
                    const imageSource = `/img/flags/${displayKey}.png`;
                    return (
                        <div
                            key={displayKey} // Use 'cs' instead of 'cz'
                            onClick={() => onClick({ key: displayKey })}
                            style={{
                                display: "inline-block",
                                margin: "10px",
                                cursor: "pointer",
                            }}
                        >
                            <img
                                src={imageSource}
                                alt={displayKey}
                                width="30"
                            />
                            <p>{language.nativeName}</p>
                        </div>
                    );
                })}
            </div>
        );

        // Determine the current language code for the button icon
        const currentLangDisplay = LanguageStore.lang === "cz" ? "cs" : LanguageStore.lang;

        return (
            <div className="settings-menu settings-menu--language">
                <span style={{ marginLeft: "5px", marginRight: "10px" }}>
                    {i18n.t("Jazyk")}
                </span>

                {/* Mobile Drawer */}
                {isMobile ? (
                    <>
                        <Button
                            type="default"
                            style={{
                                border: "0px",
                                padding: "0 10px",
                                color: "#f04e00",
                                fontSize: "15px",
                                display: "flex",
                                alignItems: "center",
                            }}
                            icon={
                                <>
                                    <img
                                        src={`/img/flags/${currentLangDisplay}.png`} // Use 'cs' instead of 'cz'
                                        width="20"
                                        alt={i18n.t(LanguageStore.getLocaleName(LanguageStore.lang))}
                                    />
                                </>
                            }
                            onClick={this.showDrawer}
                        >
                            <span style={{ marginLeft: "5px" }}>
                                <DownOutlined />
                            </span>
                        </Button>

                        <Drawer
                            title={i18n.t("Select Language")}
                            placement="right"
                            closable
                            onClose={this.closeDrawer}
                            open={drawerVisible} 
                            styles={{ body: { padding: 0 } }}
                        >
                            {drawerContent}
                        </Drawer>
                                            </>
                ) : (
                    // Desktop Dropdown
                    <Dropdown
                        menu={{
                            items,
                            onClick,
                        }}
                        onOpenChange={(open) => {
                            const body = document.body;
                            if (open) {
                                body.style.top = `-${window.scrollY}px`;
                                body.classList.add("fixed-position");
                            } else {
                                const scrollY = body.style.top;
                                body.classList.remove("fixed-position");
                                window.scrollTo(0, parseInt(scrollY || "0") * -1);
                            }
                        }}
                        trigger={["click"]}
                    >
                        <a onClick={(e) => e.preventDefault()}>
                            <span className="header__language-hide-mobile">
                                {i18n.t(LanguageStore.getLocaleName(LanguageStore.lang))}
                            </span>
                            <DownOutlined />
                        </a>
                    </Dropdown>
                )}
            </div>
        );
    }

}

export default withRouter(observer(withRouter(observer(LanguagesMenu))));
